var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('b-row',{staticClass:"mt-n32",attrs:{"align-h":"between"}},[_c('b-col',{staticClass:"hp-flex-none w-auto mt-32"}),_c('b-col',{staticClass:"hp-flex-none w-auto mt-24"},[_c('b-row',[_c('b-col',{staticClass:"hp-flex-none w-auto pr-0"},[(
                _vm.currentUser &&
                _vm.checkIfHaveRole(_vm.currentUser.rol, [
                  _vm.ROLES.superadmin,
                  _vm.ROLES.coordinator,
                ]) &&
                _vm.project
              )?_c('b-button',{staticClass:"btn-ghost",attrs:{"href":`${_vm.urlProyectsPlataform}/proyectos/lugaresInstalacion/${_vm.project.code}?sap_project=${_vm.project.code}`,"target":"_blank","variant":"primary"}},[_vm._v("Agregar Punto de instalación")]):_vm._e()],1)],1)],1)],1)],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-0"},[_vm._v("Puntos de instalación")]),_c('h5',{staticClass:"mb-0 text-black-80 hp-text-color-dark-30"},[_vm._v(" Listado de todos los puntos ")])]),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","sm":"12"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Buscar por nombre"},on:{"input":function($event){return _vm.getPlacesData()}},model:{value:(_vm.filters.name),callback:function ($$v) {_vm.$set(_vm.filters, "name", $$v)},expression:"filters.name"}})],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","sm":"12"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Buscar por código"},on:{"input":function($event){return _vm.getPlacesData()}},model:{value:(_vm.filters.code),callback:function ($$v) {_vm.$set(_vm.filters, "code", $$v)},expression:"filters.code"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[(_vm.wrappedPlaces.length)?_c('b-row',_vm._l((_vm.wrappedPlaces),function(item,index){return _c('b-col',{key:index,staticClass:"mb-32",attrs:{"cols":"12","md":"6","xl":"6"}},[_c('card-basic',{attrs:{"title":item.name,"desc":item.code,"subtitle":item.address ? item.address : 'Sin dirección',"img":item.image},on:{"click":function($event){return _vm.goToShow(item)}}})],1)}),1):_c('h5',{staticClass:"text-center mt-16"},[_vm._v(" Listado de puntos de instalación vacío ")])],1),_c('update-or-create-place',{ref:"formPlace",on:{"success":function($event){return _vm.getPlaces({
        project_id: _vm.$route.params.id,
      })}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }