<template>
  <b-row>
    <b-col cols="12" class="mb-32">
      <b-row align-h="between" class="mt-n32">
        <b-col class="hp-flex-none w-auto mt-32"> </b-col>
        <b-col class="hp-flex-none w-auto mt-24">
          <b-row>
            <b-col class="hp-flex-none w-auto pr-0">
              <b-button
                v-if="
                  currentUser &&
                  checkIfHaveRole(currentUser.rol, [
                    ROLES.superadmin,
                    ROLES.coordinator,
                  ]) &&
                  project
                "
                :href="`${urlProyectsPlataform}/proyectos/lugaresInstalacion/${project.code}?sap_project=${project.code}`"
                target="_blank"
                variant="primary"
                class="btn-ghost"
                >Agregar Punto de instalación</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" class="mb-32">
      <h2 class="mb-0">Puntos de instalación</h2>
      <h5 class="mb-0 text-black-80 hp-text-color-dark-30">
        Listado de todos los puntos
      </h5>
    </b-col>

    <b-col cols="12" class="mb-32">
      <b-row>
        <b-col cols="12" xl="6" md="6" sm="12">
          <b-form-input
            v-model="filters.name"
            type="text"
            @input="getPlacesData()"
            placeholder="Buscar por nombre"
          ></b-form-input>
        </b-col>
        <b-col cols="12" xl="6" md="6" sm="12">
          <b-form-input
            v-model="filters.code"
            type="text"
            @input="getPlacesData()"
            placeholder="Buscar por código"
          ></b-form-input>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12">
      <b-row v-if="wrappedPlaces.length">
        <b-col
          cols="12"
          md="6"
          xl="6"
          :key="index"
          v-for="(item, index) in wrappedPlaces"
          class="mb-32"
        >
          <card-basic
            @click="goToShow(item)"
            :title="item.name"
            :desc="item.code"
            :subtitle="item.address ? item.address : 'Sin dirección'"
            :img="item.image"
          ></card-basic>
        </b-col>
      </b-row>
      <h5 v-else class="text-center mt-16">
        Listado de puntos de instalación vacío
      </h5>
    </b-col>

    <update-or-create-place
      ref="formPlace"
      @success="
        getPlaces({
          project_id: $route.params.id,
        })
      "
    ></update-or-create-place>
  </b-row>
</template>

<script>
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import CardBasic from "@/view/components/common/cards/CardBasic.vue";
import { mapActions, mapGetters } from "vuex";
import UpdateOrCreatePlace from "@/view/pages/app/places/updateOrCreatePlace.vue";
import { verifyRole } from "@/mixins/role";

export default {
  mixins: [verifyRole],
  components: {
    PageTitle,
    CardBasic,
    UpdateOrCreatePlace,
  },
  mounted() {
    this.getPlacesData();
  },
  data() {
    return {
      loading: false,
      filters: {
        name: "",
        code: "",
      },
      debounce: null,
      debounceTime: 400,
      urlProyectsPlataform: process.env.VUE_APP_PROYECTS_PLATAFORM_URL,
    };
  },
  methods: {
    ...mapActions({
      getPlaces: "place/getPlaces",
    }),
    async getPlacesData() {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = setTimeout(async function () {
        await self.getPlaces({
          project_id: self.$route.params.id,
          name: self.filters.name ?? undefined,
          code: self.filters.code ?? undefined,
        });
        self.loading = false;
      }, self.debounceTime);
    },
    goToShow(item) {
      this.$router.push({
        name: "show-place-checklists",
        params: {
          id: item.id,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      project: "project/project",
      places: "place/places",
      currentUser: "auth/currentUser",
    }),
    wrappedPlaces() {
      return this.places.map((place) => {
        return {
          ...place,
          image: place.image
            ? place.image
            : require("@/assets/img/app/places/place-icon.png"),
        };
      });
    },
  },
};
</script>
